import React from "react";
import { useSelector } from "react-redux";
import { FlexibleWritingState } from "../store";

interface Props {
  onSelectedValueChanged: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const writingStyleOptions = [
  "Analytical",
  "Humorous",
  "Optimistic",
  "Personal",
  "Playful",
];

export const WritingStyleSelect = (props: Props) => {
  const state = useSelector((state: FlexibleWritingState) => state);

  return (
    <div className="row g-3 mb-2">
      <div className="col-auto">
        <label htmlFor="document-type" className="col-form-label">
          Writing style:
        </label>
      </div>
      <div className="col-auto">
        <select
          id="document-type"
          className="form-select"
          aria-label="Default select example"
          onChange={props.onSelectedValueChanged}
          disabled={
            state.processingOutline ||
            state.processingOutput ||
            state.processingQuestions ||
            state.processingOverallTranscript ||
            !!state.processingAudioObject ||
            !!state.recordingObject
          }
          value={props.value}
        >
          {writingStyleOptions.map((value) => (
            <option value={value} label={value} key={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
