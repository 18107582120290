import { useDispatch } from "react-redux";
import { writingActions } from "../store";
import { useTransport } from "./use-transport";
import { useCallback } from "react";

export const useContentGenerator = () => {
  const dispatch = useDispatch();
  const transport = useTransport();

  const generateOutline = useCallback(
    async (revalidate) => {
      dispatch(writingActions.setProcessingOutline(true));
      await transport.post("/generate_outline");
      await revalidate();
      dispatch(writingActions.setProcessingOutline(false));
    },
    [dispatch, transport]
  );

  const generateQuestions = useCallback(
    async (revalidate) => {
      dispatch(writingActions.setProcessingQuestions(true));
      await transport.post("/generate_questions");
      await revalidate();
      dispatch(writingActions.setProcessingQuestions(false));
    },
    [dispatch, transport]
  );

  const generateOutput = useCallback(
    async (revalidate) => {
      dispatch(writingActions.setProcessingOutput(true));
      await transport.post("/generate_output");
      await revalidate();
      dispatch(writingActions.setProcessingOutput(false));
    },
    [dispatch, transport]
  );

  const generateData = useCallback(
    async (revalidate) => {
      await Promise.all([
        generateOutline(revalidate),
        generateQuestions(revalidate),
        generateOutput(revalidate),
      ]);
    },
    [generateOutline, generateOutput, generateQuestions]
  );

  return {
    generateData,
    generateOutput,
  };
};
