import React, { useEffect, useState } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { FlexibleWritingState, writingActions } from "./store/index";
import { useDispatch } from "react-redux";
import { Output } from "./components/output";
import { Questions } from "./components/questions";
import { RecordButton } from "./components/record-button";
import { Outline } from "./components/outline";
import { GoogleTagScript } from "./components/google-tag-script";
import { VERSION } from "./version";
import { useTransport, useTransportSWR } from "./hooks/use-transport";
import { CookiesModal } from "./components/cookies-modal";
import { useContentGenerator } from "./hooks/use-content-generator";
import { Responses } from "./components/responses";
import { useAuth0 } from "@auth0/auth0-react";
import { SuggestedTopics } from "./components/suggested-topics";
import { ShortTranscriptAlert } from "./components/short-transcript-alert";
import { Header } from "./components/header";
import { TitleEdit } from "./components/title-edit";
import { BetaFeature } from "./components/beta-feature";
import { TypingPrototype } from "./components/typing-prototype";
import { useEventTracking } from "./hooks/use-event-tracking";
import { DatadogLogging } from "./components/datadog-logs";
import { WritingStyleSelect } from "./components/writing-style-select";

const App = () => {
  const dispatch = useDispatch();
  const transport = useTransport();
  const { trackEvent } = useEventTracking();

  const { data, isLoading, revalidate } = useTransportSWR(
    transport,
    "/get_document",
    {
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: false,
    }
  );

  const state = useSelector((state: FlexibleWritingState) => state);
  const [showAlert, setShowAlert] = useState(false);

  const [showExampleTopics, setShowExampleTopics] = useState(false);
  const [showLoginAlertModal, setShowLoginAlertModal] = useState(false);
  const { generateData } = useContentGenerator();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (state.version !== VERSION) {
      dispatch(writingActions.resetStore());
      dispatch(writingActions.setVersion(VERSION));
    }
  }, [dispatch, state.version]);

  if (isLoading || !data) {
    return <></>;
  }

  const document = data["document"];

  const setTranscript = async (_, rawTranscript, audioDuration, fileURL) => {
    dispatch(writingActions.setProcessingOverallTranscript(true));

    const { data: transcript } = await transport.post(
      "/cleanup_standalone_recording",
      {
        rawTranscript,
      }
    );

    const transcriptWordsCount = transcript.split(" ").length;

    if (transcriptWordsCount < 20) {
      dispatch(writingActions.setProcessingOverallTranscript(false));
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }

    await transport.post("/set_overall_transcript", { transcript });

    await revalidate();
    dispatch(writingActions.setProcessingOverallTranscript(false));

    await generateData(revalidate);

    trackEvent("recorded_main", {
      rawTranscript,
      processedTranscript: transcript,
      audioDuration,
      fileURL,
    });
  };

  const handleWritingStyleChanged = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    await transport.post("/set_writing_style", {
      writingStyle: event.target.value,
    });

    await generateData(revalidate);
  };

  const onSignInAlert = async () => {
    setShowLoginAlertModal(false);
    await loginWithRedirect();
  };

  return (
    <div>
      <Header />
      <div className="App">
        <CookiesModal />
        <DatadogLogging />
        <GoogleTagScript />
        {showLoginAlertModal && (
          <div className="modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    Sign in to save your document
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowLoginAlertModal(false);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Otherwise, we will not save your document.</p>
                </div>
                <div className="modal-footer text-center justify-content-center">
                  <button
                    type="button"
                    className="accent-button"
                    data-bs-dismiss="modal"
                    onClick={onSignInAlert}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="main">
          {state.processingOverallTranscript && (
            <section className="py-5 text-center container">
              <div className="row py-lg-5 justify-content-center align-items-center">
                <div className="col-lg-6 col-md-8 mx-auto"></div>
                <div className="fw-light mb-3">
                  <div className="processing lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <h2 className="fw-bold mb-3">Generating synthesis...</h2>
                  <h6 className="fw-light mb-3">
                    Please wait a little bit for the result, it usually takes no
                    more than 10 seconds
                  </h6>
                </div>
              </div>
            </section>
          )}
          {!state.processingOverallTranscript &&
            !document?.overall_transcript && (
              <section className="py-5 text-center container">
                <div className="row py-lg-5 justify-content-center align-items-center">
                  <div className="col-lg-6 col-md-8 mx-auto">
                    <h1 className="fw-light mb-3">
                      You talk🎙️, We'll write✍️!
                    </h1>
                    <p className="lead text-body-secondary mb-4">
                      Talk for a minute or two.
                      <br />
                      We'll help you transform your ideas into clear writing.
                    </p>
                    <div className="d-lg-flex justify-content-center align-items-center gap-3">
                      <RecordButton
                        cssType={"accent-button"}
                        onText={"Start Recording"}
                        offText={"Stop Recording"}
                        objectId={"main"}
                        setTranscript={setTranscript}
                        layout={"2-button"}
                      />
                      {!showExampleTopics && (
                        <button
                          type="button"
                          className="btn text-primary"
                          onClick={() => setShowExampleTopics(true)}
                        >
                          Example Topics
                        </button>
                      )}
                    </div>
                    {showExampleTopics && (
                      <SuggestedTopics setTranscript={setTranscript} />
                    )}
                    <BetaFeature>
                      <TypingPrototype
                        setTranscript={setTranscript}
                        setShowAlert={setShowAlert}
                      />
                    </BetaFeature>
                    {showAlert && <ShortTranscriptAlert />}
                  </div>
                </div>
              </section>
            )}
        </div>
        {!state.processingOverallTranscript &&
          !!document?.overall_transcript && (
            <div className="container my-4">
              <div className="row">
                <div className="col">
                  <TitleEdit title={document.title} />
                </div>
                <div className="col d-flex justify-content-end">
                  <WritingStyleSelect
                    onSelectedValueChanged={handleWritingStyleChanged}
                    value={document?.writing_style}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col align-self-end text-lg-end text-end mb-4">
                  {!isAuthenticated && !!document.overall_transcript && (
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        if (!isAuthenticated) setShowLoginAlertModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-bookmark me-1"
                        viewBox="0 0 18 18"
                      >
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                      </svg>
                      Save note
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Questions
                    questions={document?.questions}
                    writingStyle={document?.writing_style}
                    unansweredQuestions={document?.unanswered_questions}
                    answeredQuestions={document?.answered_questions}
                    questionsComments={document?.questions_comments}
                    thumbsUpQuestions={document?.thumbs_up_questions}
                    thumbsDownQuestions={document?.thumbs_down_questions}
                    revalidate={revalidate}
                  />
                  <Outline
                    outline={document?.outline}
                    revalidate={revalidate}
                  />
                </div>
                <div className="col-lg-6">
                  <Output
                    outputText={document?.output}
                    revalidate={revalidate}
                    documentType={document?.document_type}
                    writingStyle={document?.writing_style}
                    wordCount={document?.word_count}
                    setShowLoginAlertModal={setShowLoginAlertModal}
                  />
                  <Responses
                    overallTranscript={document?.overall_transcript}
                    responses={document?.responses}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default App;
