import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { FlexibleWritingState, writingActions } from "../store";

export const Collapsible = ({ title, children }) => {
  const dispatch = useDispatch();
  const collapsibleStates = useSelector(
    (state: FlexibleWritingState) => state.collapsibleStates
  );

  if (!children?.length) {
    return null;
  }

  const formattedTitle = title.replace(" ", "-");

  const currentState =
    collapsibleStates[formattedTitle] === undefined
      ? true
      : collapsibleStates[formattedTitle];

  const toggleState = () => {
    dispatch(
      writingActions.setCollapsibleState({
        id: formattedTitle,
        state: !currentState,
      })
    );
  };

  return (
    <div className="accordion" id={`chapters-${formattedTitle}`}>
      <div className="accordion-item mb-3">
        <h2 className="accordion-header" id={`heading-${formattedTitle}`}>
          <button
            className={
              `accordion-button` + (!!currentState ? "" : " collapsed")
            }
            type="button"
            onClick={toggleState}
          >
            <b>{title}</b>
          </button>
        </h2>
        <div
          id={`chapter-${formattedTitle}`}
          className={
            `accordion-collapse ` + (!!currentState ? "show" : "collapse")
          }
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
