import { useAuth0 } from "@auth0/auth0-react";
import { useTransport, useTransportSWR } from "./use-transport";
import { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

export const useAccount = () => {
  const { user, isAuthenticated } = useAuth0();

  const transport = useTransport();
  const { data, revalidate, isLoading } = useTransportSWR(transport, "/auth", {
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnFocus: false,
  });

  const account = data?.account;

  useEffect(() => {
    const createUser = async () => {
      await transport.post("/auth", {
        anon: localStorage.getItem("anon").replaceAll('"', ""),
        email: user.email,
        name: user.name,
        profile_image: user.picture,
      });

      await revalidate();
    };

    if (!!user && isAuthenticated && !account && !isLoading) {
      createUser();
    }
  }, [account, isAuthenticated, user, revalidate, transport, isLoading]);

  useEffect(() => {
    revalidate();
  }, [isAuthenticated, revalidate]);

  useEffect(() => {
    if (!!account?.email || !!account?.name) {
      datadogLogs.setUser({ email: account.email, name: account.name });
      datadogRum.setUser({ email: account.email, name: account.name });
    }
  }, [account?.email, account?.name]);

  return { account, revalidate, isLoading };
};
