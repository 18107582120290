import { Collapsible } from "./collapsible";

export const Responses = ({ overallTranscript, responses }) => {
  if (!overallTranscript && (!responses || responses.length === 0)) {
    return <></>;
  }

  return (
    <Collapsible title={"Responses"}>
      <h5>Initial Recording</h5>
      <p>{overallTranscript}</p>

      <ul>
        {responses?.map((r) => {
          return Object.keys(r).map((key, subIndex) => {
            return (
              <div key={subIndex}>
                <li>{key.replaceAll("^", " -> ")}</li>
                <ul>
                  <li>{r[key]}</li>
                </ul>
              </div>
            );
          });
        })}
      </ul>
    </Collapsible>
  );
};
