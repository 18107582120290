import { useEffect, useState } from "react";
import { useTransport } from "../hooks/use-transport";

export const TitleEdit = ({ title }) => {
  const [inputMode, setInputMode] = useState(false);
  const [typing, setTyping] = useState("");
  const transport = useTransport();
  const [internalTitle, setInternalTitle] = useState(title); //This is used to update the title right away and not wait for the object to be re-fetched

  useEffect(() => {
    setTyping(title);
    setInternalTitle(title);
  }, [title]);

  const handleTitleSave = async (newTitle) => {
    if (newTitle !== title) {
      setInternalTitle(newTitle);
      transport.post("/update_title", { title: newTitle });
    }
  };

  return (
    <>
      {!inputMode && (
        <button
          type="button"
          className="btn btn-secondary-outline"
          onClick={() => {
            setInputMode(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            className="bi bi-pen me-1"
            viewBox="0 0 18 18"
          >
            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
          </svg>
          <b>{internalTitle}</b>
        </button>
      )}
      {inputMode && (
        <div className="mt-3 text-start">
          <input
            autoFocus={true}
            value={typing}
            defaultValue={typing}
            className="form-control mb-2"
            onChange={(e) => setTyping(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleTitleSave(typing);
                setInputMode(false);
              }
            }}
            onBlur={() => {
              handleTitleSave(typing);
              setInputMode(false);
            }}
          ></input>
        </div>
      )}
    </>
  );
};
