import { SubitemRecordComponent } from "./subitem-record";

const TOPICS_MOCK = [
  "What is your biggest goal right now?",
  "What metric or KPI matters most in your work? What are you doing to improve it?",
  "Who is someone that's helped you get where you are today?",
  "What lesson can you learn and share from an experience you had in the last month?",
];

export const SuggestedTopics = ({ setTranscript }) => {
  return (
    <div className="alert alert-topic text-start pb-0 mt-5" role="alert">
      {TOPICS_MOCK.map((t) => (
        <SubitemRecordComponent
          key={t}
          text={t}
          onText={"Record Thoughts"}
          offText={"Stop"}
          objectId={t}
          setTranscript={setTranscript}
        />
      ))}
    </div>
  );
};
