import { useSelector } from "react-redux";
import { MicRecorder } from "../utils/mic-recorder";
import { useRef, useState } from "react";
import { FlexibleWritingState } from "../store";

export const useMicRecorder = () => {
  const micRecorder = useRef<MicRecorder>(new MicRecorder());
  const [recording, setRecording] = useState(false);
  const audioInputDevice = useSelector(
    (state: FlexibleWritingState) => state.audioInputDevice
  );

  return {
    start: async () => {
      await micRecorder.current.start(audioInputDevice);
      setRecording(true);
    },
    stop: async () => {
      await micRecorder.current.stop();
      setRecording(false);
    },
    getFile: async () => {
      const { file, audioDuration } = await micRecorder.current.getFile();
      return { file, audioDuration };
    },
    isRecording: recording,
  };
};
