import EmptyStoriesIcon from "../../assets/icons/empty-stories.svg";
import { Header } from "../header";
import { useTransport, useTransportSWR } from "../../hooks/use-transport";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const BookmarkIcon = ({ isActive = false, onClick = () => {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#396B9A"
    className="bi bi-bookmark me-4"
    viewBox="0 0 16 16"
    role="button"
    onClick={onClick}
  >
    {isActive ? (
      <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
    ) : (
      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"></path>
    )}
  </svg>
);

const TrashIcon = ({ isActive = false, onClick = () => {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#B84242"
    className="bi bi-bookmark me-4"
    viewBox="0 0 16 16"
    role="button"
    onClick={onClick}
  >
    {isActive ? (
      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
    ) : (
      <>
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
      </>
    )}
  </svg>
);

const PlusIcon = ({ onClick = () => {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-plus-lg me-2"
    viewBox="0 0 16 16"
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
    />
  </svg>
);

export const MyRecordings = () => {
  const transport = useTransport();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [documents, setDocuments] = useState([]); //This is used to update the title right away and not wait for the object to be re-fetched

  const { data } = useTransportSWR(transport, "/get_documents", {
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnFocus: false,
  });

  const sortDocumentsByDate = (documents) => {
    documents.sort((a, b) => {
      const firstDate = new Date(a.created_at).getTime();
      const secondDate = new Date(b.created_at).getTime();

      if (a.bookmarked) {
        return b.bookmarked ? secondDate - firstDate : -1;
      }

      return b.bookmarked ? 1 : secondDate - firstDate;
    });
  };

  useEffect(() => {
    if (!!data) {
      sortDocumentsByDate(data.documents);
      setDocuments(data.documents);
    }
  }, [data]);

  if (!isAuthenticated) {
    return <>INVALID ACCESS</>;
  }

  if (!data) {
    return <></>;
  }

  const createNewDocument = async () => {
    await transport.post("/set_active_document", {
      document_id: null,
    });
    navigate("/");
  };

  if (documents.length === 0) {
    return (
      <div>
        <Header />
        <div
          className="container d-flex flex-column align-items-center justify-content-center m-auto text-center"
          style={{ height: "calc(100vh - 240px)" }}
        >
          <img src={EmptyStoriesIcon} alt="empty_stories" />
          <h1 className="mt-5">You don’t have any documents yet.</h1>
          <h5 className="mt-4">
            Click on “Start recording” to start your journey with Storied AI.
          </h5>
          <div
            className="accent-button justify-content-start mt-5"
            onClick={createNewDocument}
          >
            <PlusIcon />
            Start new document
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="row justify-content-between mb-4">
            <div className="col">
              <h2 className="fw-semibold">My Stories</h2>
            </div>
            <div className="col text-end">
              <div
                className="accent-button justify-content-start"
                onClick={createNewDocument}
              >
                <PlusIcon />
                Start new document
              </div>
            </div>
          </div>
          {documents.map((doc, index) => {
            const date = new Date(doc.created_at).toLocaleString();
            let transcriptLines = doc.overall_transcript;

            if (transcriptLines.length > 200) {
              transcriptLines = transcriptLines.substring(0, 200) + "...";
            }

            return (
              <div key={index} className="card mb-3">
                <div className="card-body py-2 row align-items-center">
                  <div
                    className="col-9 document-list-item"
                    onClick={async () => {
                      await transport.post("/set_active_document", {
                        document_id: doc.id,
                      });
                      await navigate("/");
                    }}
                  >
                    <p className="text-black-50 mb-2">{date}</p>
                    <h4 className="fw-semibold mb-1">{doc.title}</h4>
                    <p className="mb-0">{transcriptLines}</p>
                  </div>
                  <div className="col text-end">
                    <BookmarkIcon
                      isActive={doc.bookmarked}
                      onClick={async () => {
                        setDocuments((prevDocuments) => {
                          const copyDocuments = [...prevDocuments];
                          const currentDoc = copyDocuments.find(
                            (d) => d.id === doc.id
                          );

                          currentDoc.bookmarked = !currentDoc.bookmarked;

                          sortDocumentsByDate(copyDocuments);

                          return copyDocuments;
                        });

                        transport.post("/toggle_bookmark", {
                          document_id: doc.id,
                        });
                      }}
                    />
                    <TrashIcon
                      onClick={async () => {
                        setDocuments((prevDocuments) =>
                          prevDocuments.filter((d) => d.id !== doc.id)
                        );

                        transport.post("/delete_document", {
                          document_id: doc.id,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};
