import { useAccount } from "./use-account";

export const useBetaFeatures = () => {
  const { account } = useAccount();

  return {
    allowBetaFeatures: [
      "mircea@pondercoach.com",
      "koshnick@bestoried.ai",
      "frank@bestoried.ai",
      "dansbecker@gmail.com",
    ].includes(account?.email),
  };
};
