import React from "react";

interface Props {
  onSelectedValueChanged: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue: string;
}

export const DocumentTypeSelect = (props: Props) => {
  return (
    <div className="row g-3 mb-2">
      <div className="col-auto">
        <label htmlFor="document-type" className="col-form-label">
          Select document type:
        </label>
      </div>
      <div className="col-auto">
        <select
          id="document-type"
          className="form-select"
          aria-label="Default select example"
          defaultValue={props.defaultValue}
          onChange={props.onSelectedValueChanged}
        >
          <option value="overview" label="Overview">
            Overview
          </option>
          <option value="blog post" label="Blog or social media post">
            Blog or social media post
          </option>
          <option value="corporate memo" label="Corporate memo">
            Corporate memo
          </option>
          <option value="email message" label="Informal email or slack message">
            Informal email or slack message
          </option>
        </select>
      </div>
    </div>
  );
};
