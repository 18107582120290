import { useDispatch, useSelector } from "react-redux";
import { useMicRecorder } from "../hooks/use-mic-recorder";
import { FlexibleWritingState, writingActions } from "../store/index";
import { useTransport } from "../hooks/use-transport";
import { useEventTracking } from "../hooks/use-event-tracking";

export const RecordButton = ({
  cssType,
  onText,
  offText,
  objectId,
  setTranscript,
  layout,
}) => {
  const dispatch = useDispatch();

  const { recordingObject, processingAudioObject } = useSelector(
    (state: FlexibleWritingState) => state
  );

  const { trackError } = useEventTracking();

  const {
    start,
    stop,
    getFile,
    isRecording: isMicRecorderRecording,
  } = useMicRecorder();

  const transport = useTransport();

  const isProcessingThisObject = processingAudioObject === objectId;

  const isRecording = !!recordingObject;
  const isRecordingThisObject = isMicRecorderRecording;

  let buttonText = onText;
  let disableStatus = false;

  if (isProcessingThisObject) {
    buttonText = "Processing";
    disableStatus = true;
  } else {
    buttonText = onText;
  }

  if (isRecording) {
    if (isRecordingThisObject) {
      disableStatus = false;
      buttonText = offText;
    } else {
      disableStatus = true;
      buttonText = onText;
    }
  }

  const startRecording = async () => {
    dispatch(writingActions.setRecordingObject(objectId));

    try {
      await start();
    } catch (err) {
      dispatch(writingActions.setRecordingObject(null));
      trackError("error_starting_recording", err);
    }
  };

  const stopRecording = async () => {
    dispatch(writingActions.setRecordingObject(null));

    dispatch(writingActions.setProcessingAudioObject(objectId));

    let file,
      audioDuration = null;

    try {
      await stop();
      const resp = await getFile();

      file = resp.file;
      audioDuration = resp.audioDuration;
    } catch (err) {
      trackError("error_getting_file", err);
      alert("Processing audio failed, please try again!");
    }

    if (!!file) {
      const formData = new FormData();
      formData.append(file.name, file, file.name);

      const {
        data: { transcript, fileURL },
      } = await transport.post("/get_transcript", formData);

      await setTranscript(objectId, transcript, audioDuration, fileURL);
    }

    dispatch(writingActions.setProcessingAudioObject(null));
  };

  if (layout === "1-button") {
    return (
      <button
        className={cssType}
        disabled={disableStatus}
        onClick={async () => {
          if (!isRecording) {
            await startRecording();
          } else {
            await stopRecording();
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-mic"
          viewBox="0 0 20 20"
        >
          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
          <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
        </svg>
        {buttonText}
      </button>
    );
  } else if (layout === "2-button") {
    return (
      <>
        {!(isRecording && isRecordingThisObject) && (
          <button
            className={`${cssType}`}
            onClick={startRecording}
            disabled={disableStatus}
          >
            {disableStatus && isProcessingThisObject ? "Processing" : onText}
          </button>
        )}

        {isRecording && isRecordingThisObject && (
          <button className={`${cssType} cancel`} onClick={stopRecording}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-circle-fill me-2"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="6" />
            </svg>
            {offText}
          </button>
        )}
      </>
    );
  }
};
