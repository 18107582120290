import { SubitemRecordComponent } from "./subitem-record";

export const ChapterComponent = ({ title, prefix, items, setTranscript }) => {
  return (
    <>
      {prefix.split("^").length > 1 ? (
        <SubitemRecordComponent
          text={title}
          onText={"Add More"}
          offText={"Stop"}
          objectId={prefix}
          setTranscript={setTranscript}
        />
      ) : (
        <h4 className="mb-3">{title}</h4>
      )}
      <ul>
        {items.map((item, index) => {
          return (
            <li className="mb-2" key={index}>
              <SubitemRecordComponent
                text={item}
                onText={"Add More"}
                offText={"Stop"}
                objectId={`${prefix}^${item}`}
                setTranscript={setTranscript}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};
