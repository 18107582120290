export const ShortTranscriptAlert = () => {
  return (
    <div className="alert alert-warning mt-4" role="alert">
      Recording has less than 20 words.
      <br />
      <br />
      This isn't enough to write from.
      <br />
      Try talking about whatever issue you have been thinking about recently.
      <br />
      Do you need to prepare for a talk?
      <br />
      Or communicate a decision?
    </div>
  );
};
