import {
  createSlice,
  configureStore,
  PayloadAction,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  REHYDRATE,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "version",
    "collapsibleStates",
    "showCookiesModal",
    "audioInputDevice",
    "audioInputDeviceList",
  ],
};

export interface OutlineObject {
  json: {};
}

export interface FlexibleWritingState {
  version: string;
  recordingObject: string;
  processingAudioObject: string;
  processingOutput: boolean;
  processingQuestions: boolean;
  processingOutline: boolean;
  processingOverallTranscript: boolean;
  thumbsUpQuestions: Array<string>;
  thumbsDownQuestions: Array<string>;
  collapsibleStates: {};
  showCookiesModal: boolean;
  audioInputDevice: { label: string; deviceId: string };
  audioInputDeviceList: { label: string; deviceId: string }[];
}

const initialState: FlexibleWritingState = {
  version: null,
  recordingObject: null,
  processingAudioObject: null,
  processingOutput: false,
  processingQuestions: false,
  processingOutline: false,
  processingOverallTranscript: false,
  collapsibleStates: { Responses: false },
  thumbsUpQuestions: null,
  thumbsDownQuestions: null,
  showCookiesModal: true,
  audioInputDevice: null,
  audioInputDeviceList: [],
};

const writingSlice = createSlice({
  name: "flexible-writing",
  initialState,
  reducers: {
    setShowCookiesModal(
      state: FlexibleWritingState,
      { payload }: PayloadAction<boolean>
    ) {
      state.showCookiesModal = payload;
    },
    setRecordingObject(state: FlexibleWritingState, { payload }) {
      state.recordingObject = payload;
    },
    setProcessingOutput(state: FlexibleWritingState, { payload }) {
      state.processingOutput = payload;
    },
    setProcessingQuestions(state: FlexibleWritingState, { payload }) {
      state.processingQuestions = payload;
    },
    setProcessingOutline(state: FlexibleWritingState, { payload }) {
      state.processingOutline = payload;
    },
    setProcessingAudioObject(state: FlexibleWritingState, { payload }) {
      state.processingAudioObject = payload;
    },
    setProcessingOverallTranscript(state: FlexibleWritingState, { payload }) {
      state.processingOverallTranscript = payload;
    },
    setVersion(state: FlexibleWritingState, { payload }) {
      state.version = payload;
    },
    setCollapsibleState(state: FlexibleWritingState, { payload }) {
      state.collapsibleStates[payload.id] = payload.state;
    },
    setAudioInputDevice(state: FlexibleWritingState, { payload }) {
      state.audioInputDevice = payload;
    },
    setAudioInputDeviceList(state: FlexibleWritingState, { payload }) {
      state.audioInputDeviceList = payload;
    },
    addThumbsUpQuestion(state: FlexibleWritingState, { payload }) {
      state.thumbsUpQuestions.push(payload);
    },
    addThumbsDownQuestion(state: FlexibleWritingState, { payload }) {
      state.thumbsDownQuestions.push(payload);
    },
    removeThumbsUpQuestion(state: FlexibleWritingState, { payload }) {
      state.thumbsUpQuestions = state.thumbsUpQuestions.filter(
        (q) => q !== payload
      );
    },
    setThumbsUpQuestions(state: FlexibleWritingState, { payload }) {
      if (!payload) {
        payload = [];
      }

      state.thumbsUpQuestions = payload;
    },
    setThumbsDownQuestions(state: FlexibleWritingState, { payload }) {
      if (!payload) {
        payload = [];
      }

      state.thumbsDownQuestions = payload;
    },
    removeThumbsDownQuestion(state: FlexibleWritingState, { payload }) {
      state.thumbsDownQuestions = state.thumbsDownQuestions.filter(
        (q) => q !== payload
      );
    },
    resetStore(state: FlexibleWritingState) {
      state.recordingObject = null;
      state.processingAudioObject = null;
      state.processingOutput = false;
      state.processingQuestions = false;
      state.processingOutline = false;
      state.thumbsUpQuestions = [];
      state.thumbsDownQuestions = [];
      state.collapsibleStates = { Responses: false };

      storage.removeItem("persist:root");
    },
  },
});

const persistedReducer = persistReducer(persistConfig, writingSlice.reducer);

export const writingActions = writingSlice.actions;

const _store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const _persistor = persistStore(_store);

export const store = _store;
export const persistor = _persistor;
