import { useBetaFeatures } from "../hooks/use-beta-features";

export const BetaFeature = ({ children }) => {
  const { allowBetaFeatures } = useBetaFeatures();

  if (allowBetaFeatures) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
