import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MyRecordings } from "./components/pages/MyRecordings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          prompt: "login",
        }}
        cacheLocation={"localstorage"}
        useRefreshTokens
      >
        <BrowserRouter>
          <Routes>
            <Route index element={<App />} />
            <Route path="recordings" element={<MyRecordings />} />
          </Routes>
        </BrowserRouter>
      </Auth0Provider>
    </PersistGate>
  </Provider>
);
