import { Lock } from "semaphore-async-await";

export class MicRecorder {
  private mediaRecorder: MediaRecorder = null;
  private mediaStream: MediaStream = null;
  private audioURL: string;
  private blob: Blob;
  private onEnd: () => {};
  private lock: Lock;
  private isRecording: boolean;

  private onMediaRecorderDataAvailable = async (e) => {
    this.blob = new Blob([e.data]);
    this.audioURL = URL.createObjectURL(this.blob);
  };

  private onMediaRecorderStop = async () => {
    this.mediaStream.getAudioTracks().forEach((track) => track.stop());
    this.mediaStream = null;
    this.mediaRecorder = null;

    if (!!this.onEnd) {
      this.onEnd();
    }

    this.lock.release();
    this.isRecording = false;
  };

  private handleSuccess = async (stream) => {
    this.mediaStream = stream;
    this.mediaRecorder = new MediaRecorder(this.mediaStream);
    this.mediaRecorder.ondataavailable =
      this.onMediaRecorderDataAvailable.bind(this);
    this.mediaRecorder.onstop = this.onMediaRecorderStop.bind(this);

    this.isRecording = true;
    this.mediaRecorder.start();
  };

  initialize = () => {
    this.blob = null;
    this.audioURL = "";
    this.lock = null;
    this.isRecording = false;
  };

  getIsRecording = () => this.isRecording;

  start = async (audioInputDevice) => {
    if (!navigator.mediaDevices?.getUserMedia) {
      alert(
        "Recording only works with the https protocol. Please check the URL and try again."
      );

      throw new Error("Recording only works with the https protocol");
    }

    this.initialize();

    const constraints = !!audioInputDevice?.deviceId
      ? { deviceId: { exact: audioInputDevice.deviceId } }
      : true;

    const stream = await navigator.mediaDevices
      .getUserMedia({
        audio: constraints,
      })
      .catch((err) => {
        const errMsg =
          "Storied was not able to access your microphone.\n" +
          (err.name === "NotAllowedError"
            ? "Please try again."
            : "Please check your system privacy setting.");

        alert(errMsg);

        throw err;
      });

    this.handleSuccess(stream);
  };

  stop = async () => {
    this.lock = new Lock();
    this.lock.acquire();

    this.mediaRecorder?.stop();

    await this.lock.wait();
  };

  getFile = async () => {
    const audioContext = new AudioContext();

    const audioBuff = await audioContext.decodeAudioData(
      await this.blob.arrayBuffer()
    );

    const audioDuration = audioBuff.duration;

    const lock = new Lock();
    let file = null;

    await lock.acquire();
    const worker: Worker = new Worker("/workers/worker.js");
    worker.onmessage = (event) => {
      const { data } = event;

      file = new File(data, "rec.mp3", {
        type: "audio/mp3",
        lastModified: Date.now(),
      });

      lock.release();
    };

    ///Users/enemircea/considr-it/ponder/app/public/workers/worker.js

    worker.postMessage({
      buff: audioBuff.getChannelData(0),
      sampleRate: audioContext.sampleRate,
    });

    await audioContext.close();
    await lock.wait();

    return { file, audioDuration };
  };
}
