import { useSelector } from "react-redux";
import { ChapterComponent } from "./chapter";
import { FlexibleWritingState } from "../store";
import { Collapsible } from "./collapsible";
import { useTransport } from "../hooks/use-transport";
import { useContentGenerator } from "../hooks/use-content-generator";
import { useEventTracking } from "../hooks/use-event-tracking";

export const Outline = ({ outline, revalidate }) => {
  const state = useSelector((state: FlexibleWritingState) => state);
  const transport = useTransport();
  const { generateData } = useContentGenerator();
  const { trackEvent } = useEventTracking();

  const setTranscript = async (
    objectId,
    rawTranscript,
    audioDuration,
    fileURL
  ) => {
    const { data: transcript } = await transport.post(
      "/cleanup_standalone_recording",
      {
        rawTranscript,
      }
    );

    await transport.post("/outline_comment", { objectId, comment: transcript });
    await generateData(revalidate);

    trackEvent("recorded_outline", {
      outlineElement: objectId.replaceAll("^", "->"),
      rawTranscript,
      processedTranscript: transcript,
      audioDuration,
      fileURL,
    });
  };

  if (state.processingOutline) {
    return (
      <div className="py-5 text-center container">
        <div className="row py-lg-5 justify-content-center align-items-center">
          <div className="processing lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h5 className="fw-bold mb-3">Generating outline</h5>
          <h6 className="fw-light mb-3">Working to structure your thoughts</h6>
        </div>
      </div>
    );
  }

  if (!outline) {
    return null;
  }

  //Hack for backwards compatibility with outline before alphabetical order for keys was fixed
  if (!outline["0"]) {
    const keys = Object.keys(outline);

    let i = 0;
    for (const key of keys) {
      outline[i++] = { [key]: outline[key] };
      delete outline[key];
    }
  }

  return (
    <Collapsible title={"Outline"}>
      {Object.keys(outline).map((_, index) => {
        let chapterName = Object.keys(outline[index])[0];
        return (
          <ChapterComponent
            key={index}
            title={chapterName}
            setTranscript={setTranscript}
            prefix={chapterName}
            items={outline[index][chapterName]}
          ></ChapterComponent>
        );
      })}
    </Collapsible>
  );
};
