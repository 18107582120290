import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import * as uncaught from "uncaught";
import { VERSION } from "../version";
import { useEventTracking } from "../hooks/use-event-tracking";

export const DatadogLogging = () => {
  const shouldTrack = process.env.NODE_ENV === "production";
  const { trackError } = useEventTracking();

  useEffect(() => {
    if (!shouldTrack) return () => null;

    datadogLogs.init({
      clientToken: "pubb749f7de9cd42df9babce082d3df3275",
      site: "us5.datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: `flexible-writing`,
    });

    datadogLogs.addLoggerGlobalContext("version", VERSION);
    datadogLogs.addLoggerGlobalContext("host", process.env.REACT_APP_ENV);

    datadogRum.init({
      applicationId: "151d0d8b-99e9-46b3-b530-c2e2bc9e12a9",
      clientToken: "pube6b63521cada9ff5891970f5082b524f",
      site: "us5.datadoghq.com",
      service: "flexible-writing",
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: "allow",
      env: process.env.REACT_APP_ENV,
    });

    datadogRum.startSessionReplayRecording();

    uncaught.start();
    uncaught.addListener(function (error) {
      trackError("Unhandled error", error);
    });
    return () => {
      uncaught.flush();
    };
  }, [shouldTrack, trackError]);

  return <></>;
};
