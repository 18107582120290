import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useAnonymousId } from "./use-anonymous-id";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR, { Key, SWRConfiguration } from "swr";
import { useEventTracking } from "./use-event-tracking";

export const useTransport = () => {
  const [anonymousId] = useAnonymousId();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { trackError } = useEventTracking();

  return useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SERVER,
      withCredentials: true,
    });

    instance.interceptors.request.use(async (config) => {
      let token = null;

      try {
        token = await getAccessTokenSilently();
      } catch (_) {
        logout({ openUrl: false });
      }

      if (!!token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else if (!!anonymousId) {
        config.headers.Anonymous = anonymousId;
      }

      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.message.includes(419)) {
          logout({ openUrl: false });
        } else {
          trackError("server_error", error);
        }
      }
    );

    return instance;
  }, [anonymousId, getAccessTokenSilently, logout, trackError]);
};

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    "initialData"
  > {
  initialData?: Data;
}

export const useTransportSWR = <Data = any, Error = any>(
  transport: AxiosInstance,
  path: Key,
  config?: Config<Data, Error>
) => {
  const {
    data: response,
    error,
    isLoading,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    path,
    (p) => transport.get<Data>(p),
    config
  );

  return {
    data: response && response.data,
    response,
    error,
    isLoading,
    revalidate: mutate,
  };
};
