import { useState } from "react";

export const TypingPrototype = ({ setTranscript, setShowAlert }) => {
  const [inputMode, setInputMode] = useState(false);
  const [processingTyping, setProcessingTyping] = useState(false);
  const [typing, setTyping] = useState("");

  return (
    <>
      {!inputMode && (
        <button
          type="button"
          className="btn btn-secondary-outline"
          onClick={() => setInputMode(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            className="bi bi-pen me-1"
            viewBox="0 0 18 18"
          >
            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
          </svg>
          Try typing
        </button>
      )}

      {inputMode && (
        <div className="mt-3 text-start">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Type your thoughts here:
          </label>
          <textarea
            value={typing}
            className="form-control mb-2"
            id="exampleFormControlTextarea1"
            rows={3}
            onChange={(e) => setTyping(e.target.value)}
          ></textarea>
          <button
            className="btn btn-outline-secondary mt-3"
            type="button"
            disabled={processingTyping}
            onClick={async () => {
              setShowAlert(false);

              if (!typing) {
                alert("Please type somthing :(");
                return;
              }

              setProcessingTyping(true);

              await setTranscript("main", typing, -1);

              setProcessingTyping(false);
              setTyping("");
              setInputMode(false);
            }}
          >
            {processingTyping && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {processingTyping ? "Processing ..." : "Generate cool stuff !!!"}
          </button>
        </div>
      )}
    </>
  );
};
