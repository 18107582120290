import { useDispatch, useSelector } from "react-redux";
import { FlexibleWritingState, writingActions } from "../store";

export const CookiesModal = () => {
  const dispatch = useDispatch();
  const { showCookiesModal } = useSelector(
    (state: FlexibleWritingState) => state
  );

  const onClose = () => {
    dispatch(writingActions.setShowCookiesModal(false));
  };

  if (showCookiesModal) {
    return (
      <div className="modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                We Use Cookies
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                We use cookies and collect your data to improve our services.
                Your data is secure and will never be sold or shared without
                your explicit consent. By using our software, you consent to our
                use of cookies and data privacy practices.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
