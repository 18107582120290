import { useEffect } from "react";
import ReactGA from "react-ga4";

export const GoogleTagScript = () => {
  const shouldTrack = process.env.NODE_ENV === "production";

  useEffect(() => {
    if (!shouldTrack) return;

    const measureId = process.env.REACT_APP_GTAG_MEASUREMENT_ID;

    ReactGA.initialize(measureId);
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home",
    });
  }, [shouldTrack]);

  return <></>;
};
