import React, { useState } from "react";

interface Props {
  wordCount: string;
  onWordCountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const WordCountInput = (props: Props) => {
  const [inputValue, setInputValue] = useState(props.wordCount);
  const check = !/^\d+$/.test(inputValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const isValidState = event.target.value === "" || /^\d+$/.test(event.target.value);
    if (isValidState) {
      props.onWordCountChange(event);
    }
  };

  return (
    <div className="row g-3 mb-2">
      <div className="col-auto">
        <label htmlFor="word-count-input" className="col-form-label">
          Approximate output length (word count):
        </label>
      </div>
      <div className="col-auto">
        <input
          type="number"
          id="word-count-input"
          className={`form-control ${check && "is-invalid"}`}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        {check && <div className="invalid-feedback">Please enter a numeric value.</div>}
      </div>
    </div>
  );
};
