import { isIOS, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { FlexibleWritingState, writingActions } from "../store";
import { useEffect } from "react";

export const AudioInputSelector = () => {
  const dispatch = useDispatch();
  const { audioInputDeviceList, audioInputDevice, recordingObject } =
    useSelector((state: FlexibleWritingState) => state);

  useEffect(() => {
    const loadDevices = async () => {
      const devices = (await navigator.mediaDevices.enumerateDevices())
        .filter(
          (d) => d.kind === "audioinput" && d.label !== "" && d.deviceId !== ""
        )
        .map((d) => {
          return { label: d.label, deviceId: d.deviceId };
        });

      if (devices.length === 0) {
        console.log("Microphone access not granted!");
        return;
      }

      dispatch(writingActions.setAudioInputDeviceList(devices));

      if (
        !audioInputDevice ||
        !devices.find((d) => d.label === audioInputDevice.label)
      ) {
        dispatch(writingActions.setAudioInputDevice(devices[0]));
      }
    };

    if (
      !!navigator.mediaDevices?.enumerateDevices &&
      !!navigator.mediaDevices?.getUserMedia &&
      audioInputDeviceList.length === 0
    ) {
      loadDevices();
    }
  }, [audioInputDevice, audioInputDeviceList, recordingObject, dispatch]);

  const onDeviceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      writingActions.setAudioInputDevice({
        label: e.target.selectedOptions[0].label,
        deviceId: e.target.selectedOptions[0].id,
      })
    );
  };

  if (
    isMobile &&
    isIOS &&
    audioInputDeviceList.length > 1 &&
    !!audioInputDevice
  ) {
    return (
      <select
        onChange={onDeviceChange}
        value={audioInputDevice.label}
        disabled={!!recordingObject}
      >
        {audioInputDeviceList.map((d, index) => {
          return (
            <option key={index} label={d.label} id={d.deviceId}>
              {d.label}
            </option>
          );
        })}
      </select>
    );
  } else {
    return <></>;
  }
};
