import { useCallback } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { useSelector } from "react-redux";
import { FlexibleWritingState } from "../store";

export const useEventTracking = () => {
  const { audioInputDevice, audioInputDeviceList } = useSelector(
    (state: FlexibleWritingState) => state
  );

  const trackError = useCallback(
    (label: string, error: any) => {
      const payload = {
        audioInput: audioInputDevice,
        audioInputList: audioInputDeviceList,
        ...error,
      };

      if (process.env.NODE_ENV !== "production") {
        console.error(label, { payload });
        return;
      }

      datadogLogs.logger.error(label, { payload });
    },
    [audioInputDevice, audioInputDeviceList]
  );

  const trackEvent = useCallback(
    (name: string, eventData: any = null) => {
      const payload = {
        audioInput: audioInputDevice,
        audioInputList: audioInputDeviceList,
        ...eventData,
      };

      if (process.env.NODE_ENV !== "production") {
        console.log(name, { payload });
        return;
      }

      datadogLogs.logger.info(name, { payload });
    },
    [audioInputDevice, audioInputDeviceList]
  );

  return {
    trackError,
    trackEvent,
  };
};
