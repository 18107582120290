import { useDispatch, useSelector } from "react-redux";
import { FlexibleWritingState, writingActions } from "../store";
import { SubitemRecordComponent } from "./subitem-record";
import { useState, useRef, useEffect } from "react";
import { Collapsible } from "./collapsible";
import { useTransport } from "../hooks/use-transport";
import { useContentGenerator } from "../hooks/use-content-generator";
import { useEventTracking } from "../hooks/use-event-tracking";

const ELABORATIVE_QUESTION =
  "Is there anything you'd like to add to what you've already said?";

export const Questions = ({
  questions,
  writingStyle,
  answeredQuestions,
  unansweredQuestions,
  questionsComments,
  thumbsUpQuestions,
  thumbsDownQuestions,
  revalidate,
}) => {
  const transport = useTransport();
  const dispatch = useDispatch();

  const { generateData } = useContentGenerator();

  const isMounted = useRef<boolean>(false);
  const updateThumbStateTimeoutId = useRef<NodeJS.Timeout>(null);
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    dispatch(writingActions.setThumbsUpQuestions(thumbsUpQuestions));
    dispatch(writingActions.setThumbsDownQuestions(thumbsDownQuestions));
  }, [dispatch, thumbsUpQuestions, thumbsDownQuestions]);

  const [showHiddenQuestions, setShowHiddenQuestion] = useState(false);

  const {
    processingQuestions,
    thumbsUpQuestions: localThumbsUpQuestions,
    thumbsDownQuestions: localThumbsDownQuestions,
  } = useSelector((state: FlexibleWritingState) => state);

  useEffect(() => {
    const updateThumbState = () => {
      if (!!updateThumbStateTimeoutId.current) {
        clearTimeout(updateThumbStateTimeoutId.current);
      }

      updateThumbStateTimeoutId.current = setTimeout(() => {
        transport.post("/update_thumbs_state", {
          thumbsUpQuestions: localThumbsUpQuestions,
          thumbsDownQuestions: localThumbsDownQuestions,
        });

        updateThumbStateTimeoutId.current = null;
      }, 3000);
    };

    if (!!isMounted.current) {
      updateThumbState();
    } else if (!!localThumbsUpQuestions && !!localThumbsDownQuestions) {
      isMounted.current = true;
    }
  }, [localThumbsUpQuestions, localThumbsDownQuestions, transport, dispatch]);

  const setTranscript = async (objectId, rawTranscript, audioDuration, fileURL) => {
    const { data: transcript } = await transport.post("/cleanup_question", {
      rawTranscript,
      question: objectId,
    });

    await transport.post("/question_comment", {
      objectId,
      comment: transcript,
    });

    trackEvent("recorded_question", {
      question: objectId,
      rawTranscript,
      processedTranscript: transcript,
      audioDuration,
      fileURL,
    });

    await generateData(revalidate);
  };

  if (processingQuestions) {
    return (
      <section className="py-5 text-center container">
        <div className="row py-lg-5 justify-content-center align-items-center">
          <div className="processing lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h5 className="fw-bold mb-3">Generating questions</h5>
          <h6 className="fw-light mb-3">We're thinking about what you said</h6>
        </div>
      </section>
    );
  }

  if (!questions) {
    return <></>;
  }

  const combinedQuestions = Array.from(
    new Set([...questions, ELABORATIVE_QUESTION, ...unansweredQuestions])
  )
    .filter(
      (q) => q === ELABORATIVE_QUESTION || !Object.keys(answeredQuestions)?.includes(q)
    )
    .sort((a, b) => {
      const firstScore =
        Number(thumbsUpQuestions?.includes(a)) - Number(thumbsDownQuestions?.includes(a));
      const secondScore =
        Number(thumbsUpQuestions?.includes(b)) - Number(thumbsDownQuestions?.includes(b));

      return secondScore - firstScore;
    })
    .map((q, index) => {
      return {
        text: q,
        isHidden: !showHiddenQuestions && index > 5,
      };
    });

  const elaborativeQuestionIndex = combinedQuestions.findIndex(
    (q) => q.text === ELABORATIVE_QUESTION
  );

  if (
    elaborativeQuestionIndex !== 5 &&
    combinedQuestions.length >= 7 &&
    !thumbsDownQuestions?.includes(ELABORATIVE_QUESTION)
  ) {
    let temp = combinedQuestions[elaborativeQuestionIndex];
    combinedQuestions[elaborativeQuestionIndex] = combinedQuestions[5];
    combinedQuestions[5] = temp;
  }

  const showMore = combinedQuestions.length > 6;

  return (
    <>
      <Collapsible title={"Questions"}>
        <div className="mb-4" />
        {combinedQuestions?.map(
          (item, index) =>
            !item.isHidden && (
              <SubitemRecordComponent
                key={index}
                text={item.text}
                onText={
                  !!questionsComments && questionsComments[item.text]?.length > 0
                    ? "Record More"
                    : "Record Thoughts"
                }
                offText={"Stop"}
                objectId={item.text}
                setTranscript={setTranscript}
                isPinningAllowed
              ></SubitemRecordComponent>
            )
        )}
        {showMore && (
          <div className="mx-auto text-center my-2">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={() => setShowHiddenQuestion(!showHiddenQuestions)}
            >
              {!showHiddenQuestions ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-align-middle bi-chevron-down me-1"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-align-middle bi-chevron-up me-1"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
              )}
              {!showHiddenQuestions ? "Show more" : "Show less"}
            </button>
          </div>
        )}
      </Collapsible>
    </>
  );
};
