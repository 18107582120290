import { FlexibleWritingState } from "../store";
import { useSelector } from "react-redux";
import { NewlineText } from "./new-line-text";
import { WordCountInput } from "./word-count-input";
import { useState } from "react";
import { DocumentTypeSelect } from "./document-type-select";
import { Collapsible } from "./collapsible";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Popover } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { useContentGenerator } from "../hooks/use-content-generator";
import { useTransport } from "../hooks/use-transport";
import { useAuth0 } from "@auth0/auth0-react";
import { useEventTracking } from "../hooks/use-event-tracking";

export const Output = ({
  outputText,
  revalidate,
  documentType,
  writingStyle,
  wordCount,
  setShowLoginAlertModal,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { generateOutput } = useContentGenerator();
  const { isAuthenticated } = useAuth0();
  const transport = useTransport();
  const { trackEvent } = useEventTracking();

  const { processingOutput } = useSelector(
    (state: FlexibleWritingState) => state
  );

  useEffect(() => {
    Array.from(querySelectorAll('button[data-bs-toggle="popover"]')).forEach(
      (popoverNode) => new Popover(popoverNode)
    );
  });

  const handleWordCountChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isValidState =
      event.target.value === "" || /^\d+$/.test(event.target.value);
    if (isValidState) {
      await transport.post("/set_word_count", {
        wordCount: event.target.value,
      });

      await generateOutput(revalidate);
    }
  };

  const handleDocumentTypeChanged = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    await transport.post("/set_document_type", {
      documentType: event.target.value,
    });

    await generateOutput(revalidate);
  };

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(outputText);

    trackEvent("output_copied_to_clipboard", {
      documentType,
      writingStyle,
      wordCount,
      outputText,
    });

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  if (processingOutput) {
    return (
      <section className="py-5 text-center container">
        <div className="row py-lg-5 justify-content-center align-items-center">
          <div className="processing lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h5 className="fw-bold mb-3">Generating output</h5>
          <h6 className="fw-light mb-3">
            This might take some time, but it's worth it
          </h6>
        </div>
      </section>
    );
  }

  if (!outputText) {
    return <></>;
  }

  const usesMarkdown = documentType === "overview";

  return (
    <Collapsible title={"Output"}>
      <WordCountInput
        onWordCountChange={handleWordCountChange}
        wordCount={wordCount}
      ></WordCountInput>
      <DocumentTypeSelect
        onSelectedValueChanged={handleDocumentTypeChanged}
        defaultValue={documentType}
      />
      <div className="row justify-content-between align-items-center my-3">
        <div className="col text-end copy-to-clipboard">
          <>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              disabled={isCopied}
              onClick={() => {
                if (isAuthenticated) {
                  onCopyToClipboard();
                } else {
                  setShowLoginAlertModal(true);
                }
              }}
            >
              {isCopied ? (
                "✓ Copied!"
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-clipboard2"
                    viewBox="0 0 18 18"
                  >
                    <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z"></path>
                    <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"></path>
                  </svg>
                  Copy to clipboard
                </>
              )}
            </button>
          </>
        </div>
      </div>

      {usesMarkdown ? (
        <ReactMarkdown>{outputText}</ReactMarkdown>
      ) : (
        <NewlineText text={outputText}></NewlineText>
      )}
    </Collapsible>
  );
};
function querySelectorAll(
  arg0: string
): Iterable<unknown> | ArrayLike<unknown> {
  return document.querySelectorAll(arg0);
}
