import { FlexibleWritingState, writingActions } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { RecordButton } from "./record-button";
import { ThumbsUpButton } from "./thumbs-up-button";
import { ThumbsDownButton } from "./thumbs-down-button";
import { useMemo } from "react";

export const SubitemRecordComponent = ({
  text,
  bold = false,
  onText,
  offText,
  objectId,
  setTranscript,
  isPinningAllowed = false,
}) => {
  const dispatch = useDispatch();

  const { thumbsUpQuestions, thumbsDownQuestions } = useSelector(
    (state: FlexibleWritingState) => state
  );

  const processThumbsUp = () => {
    if (thumbsUpQuestions?.includes(objectId)) {
      dispatch(writingActions.removeThumbsUpQuestion(objectId));
    } else {
      dispatch(writingActions.addThumbsUpQuestion(objectId));
      // if this question is already thumbed down
      // remove it from there
      if (thumbsDownQuestions?.includes(objectId)) {
        dispatch(writingActions.removeThumbsDownQuestion(objectId));
      }
    }
  };

  const processThumbsDown = () => {
    if (thumbsDownQuestions?.includes(objectId)) {
      dispatch(writingActions.removeThumbsDownQuestion(objectId));
    } else {
      dispatch(writingActions.addThumbsDownQuestion(objectId));
      // if this question is already thumbed up
      // remove it from there
      if (thumbsUpQuestions?.includes(objectId)) {
        dispatch(writingActions.removeThumbsUpQuestion(objectId));
      }
    }
  };

  const isQuestionThumbedUp = useMemo(() => {
    return thumbsUpQuestions?.includes(objectId);
  }, [objectId, thumbsUpQuestions]);

  const isQuestionThumbedDown = useMemo(() => {
    return thumbsDownQuestions?.includes(objectId);
  }, [objectId, thumbsDownQuestions]);

  return (
    <div className="row justify-content-between mb-3">
      <div className="col-xl-7 col-12 col-lg-6">
        <div className="d-inline-flex">
          {isPinningAllowed && (
            <ThumbsUpButton
              isActive={isQuestionThumbedUp}
              onClick={processThumbsUp}
            />
          )}
          {isPinningAllowed && (
            <ThumbsDownButton
              isActive={isQuestionThumbedDown}
              onClick={processThumbsDown}
            />
          )}
          <p>{bold ? <b>{text}</b> : <>{text}</>}</p>
        </div>
      </div>
      <div className="col-xl-4 col-12 col-lg-6 text-xs-start text-lg-end">
        <RecordButton
          cssType={"btn btn-outline-secondary btn-sm"}
          onText={onText}
          offText={offText}
          objectId={objectId}
          setTranscript={setTranscript}
          layout={"1-button"}
        />
      </div>
    </div>
  );
};
